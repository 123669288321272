import { ProductListSortField } from "./products.types";
/**
 * Mapping backend sort gql type to frontend sort field (read model)
 * @param sortField
 */

export function mapSortFieldToProductOrderField(sortField) {
  switch (sortField) {
    case ProductListSortField.price:
      return "MINIMAL_PRICE";

    case ProductListSortField.date:
      return "PUBLICATION_DATE";

    case ProductListSortField.name:
      return "NAME";

    case ProductListSortField.rank:
      return "RANK";

    default:
      return "RANK";
  }
}
export function buildSortOptions(t) {
  var options = [{
    label: t.formatMessage({
      defaultMessage: "Domyślne",
      id: "products.buildSortOptions.default.label"
    })
  }, {
    label: t.formatMessage({
      defaultMessage: "Alfabetycznie, A-Z",
      id: "products.buildSortOptions.name.asc.label"
    }),
    sortField: ProductListSortField.name,
    sortOrder: "asc"
  }, {
    label: t.formatMessage({
      defaultMessage: "Alfabetycznie, Z-A",
      id: "products.buildSortOptions.name.desc.label"
    }),
    sortField: ProductListSortField.name,
    sortOrder: "desc"
  }, {
    label: t.formatMessage({
      defaultMessage: "Cena, od niskiej do wysokiej",
      id: "products.buildSortOptions.price.asc.label"
    }),
    sortField: ProductListSortField.price,
    sortOrder: "asc"
  }, {
    label: t.formatMessage({
      defaultMessage: "Cena, od wysokiej do niskiej",
      id: "products.buildSortOptions.price.desc.label"
    }),
    sortField: ProductListSortField.price,
    sortOrder: "desc"
  }, {
    label: t.formatMessage({
      defaultMessage: "Data, od najnowszych",
      id: "products.buildSortOptions.date.desc.label"
    }),
    sortField: ProductListSortField.date,
    sortOrder: "desc"
  }, {
    label: t.formatMessage({
      defaultMessage: "Data, od najstarszych",
      id: "products.buildSortOptions.date.asc.label"
    }),
    sortField: ProductListSortField.date,
    sortOrder: "asc"
  }];
  return {
    sort: {
      active: options[0],
      options: options
    }
  };
}
export function matchFetchOptionToSortOption(fetchOptions, sortOptions) {
  var _sortOptions$find;

  if (!(fetchOptions !== null && fetchOptions !== void 0 && fetchOptions.sortBy)) {
    return sortOptions[0];
  }

  return (_sortOptions$find = sortOptions.find(function (option) {
    var _fetchOptions$sortBy, _option$sortOrder, _fetchOptions$sortBy2;

    return mapSortFieldToProductOrderField(option.sortField) === ((_fetchOptions$sortBy = fetchOptions.sortBy) === null || _fetchOptions$sortBy === void 0 ? void 0 : _fetchOptions$sortBy.field) && ((_option$sortOrder = option.sortOrder) === null || _option$sortOrder === void 0 ? void 0 : _option$sortOrder.toUpperCase()) === ((_fetchOptions$sortBy2 = fetchOptions.sortBy) === null || _fetchOptions$sortBy2 === void 0 ? void 0 : _fetchOptions$sortBy2.direction);
  })) !== null && _sortOptions$find !== void 0 ? _sortOptions$find : sortOptions[0];
}
export function getFetchOptionsFromSearchParams(searchParams) {
  /**
   * Search filter
   */
  var search = undefined;

  if (searchParams.has("search")) {
    search = searchParams.get("search");
  }
  /**
   * Sorting field
   */


  var field = undefined;

  if (searchParams.has("sortField")) {
    var param = searchParams.get("sortField");

    if (Object.values(ProductListSortField).includes(param)) {
      field = mapSortFieldToProductOrderField(param);
    }
  }
  /**
   * Sorting direction
   */


  var direction = "DESC";

  if (searchParams.has("sortOrder")) {
    var _param = searchParams.get("sortOrder");

    if (_param === "asc" || _param === "desc" || _param === "ASC" || _param === "DESC") {
      direction = _param.toUpperCase();
    }
  }

  return {
    shouldSortOrFilter: !!field || !!search,
    filter: {
      search: search
    },
    sortBy: {
      field: field,
      direction: direction
    }
  };
}