import _objectWithoutProperties from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectDestructuringEmpty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectDestructuringEmpty.js";
var _excluded = ["label"];
var __jsx = React.createElement;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useRouter } from "next/router";
import React from "react";
import { useIntl } from "react-intl";
import { Loader, Menu, Skeleton } from "@/components/ui";
import useRegion from "@/hooks/useRegion.hook";
import useProducts from "@/products/hooks/useProducts.hook";
import { pagesPath } from "~/src/$path";
import styles from "./Sort.styles";

var Sort = function Sort(_ref) {
  _objectDestructuringEmpty(_ref);

  var t = useIntl();

  var _useProducts = useProducts(),
      sort = _useProducts.sort,
      ready = _useProducts.ready,
      loading = _useProducts.loading;

  var _useRegion = useRegion(),
      currentChannel = _useRegion.currentChannel,
      currentLocale = _useRegion.currentLocale;

  var router = useRouter();

  var handleSelect = function handleSelect(_ref2) {
    var sortField = _ref2.sortField,
        sortOrder = _ref2.sortOrder;
    var collectionId = router.query.collectionId;
    if (Array.isArray(collectionId)) collectionId = collectionId[0];

    var root = pagesPath._channel(currentChannel.slug)._locale(currentLocale);

    var baseUrl = collectionId ? root.products._collectionId(collectionId).$url() : root.products.$url();

    var baseQuery = _objectSpread({}, baseUrl.query);

    if (!sortOrder) {
      return router.replace(baseUrl);
    }

    var query = _objectSpread(_objectSpread({}, baseQuery), {}, {
      sortOrder: sortOrder
    });

    if (sortField) {
      query.sortField = sortField;
    }

    return router.replace(_objectSpread(_objectSpread({}, baseUrl), {}, {
      query: query
    }));
  };

  if (!ready) {
    return __jsx("div", {
      className: styles.root()
    }, __jsx(Skeleton, {
      width: 96,
      height: 12,
      className: styles.skeleton()
    }));
  }

  if (!sort) return null;
  return __jsx("div", {
    className: styles.root()
  }, loading ? __jsx("div", {
    className: styles.loader()
  }, __jsx(Loader, {
    size: "sm",
    type: "spinner",
    single: true
  })) : null, __jsx(Menu, {
    caption: t.formatMessage({
      defaultMessage: "Sortowanie",
      id: "sort.menu.caption"
    }),
    width: "16.5rem",
    options: [sort.options.map(function (_ref3) {
      var label = _ref3.label,
          opt = _objectWithoutProperties(_ref3, _excluded);

      return {
        label: label,
        onClick: function onClick() {
          return handleSelect(opt);
        }
      };
    })],
    disabled: !router.isReady || loading
  }, sort.active.label));
};

Sort.displayName = "Sort";
export default Sort;