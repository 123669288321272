import _objectDestructuringEmpty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectDestructuringEmpty.js";
var __jsx = React.createElement;
import { styled as _styled } from "../../../../libraries/stitches.library.ts";
import React from "react";
import { useIntl } from "react-intl";
import { Button, Card, Rating, Skeleton } from "@/components/ui";
import styles from "./Product.styles";

var _TwComponent = _styled(Rating, {
  "marginTop": "1.5rem",
  "marginBottom": "1.5rem"
});

var _TwComponent2 = _styled(Skeleton, {
  "marginRight": "0.75rem"
});

var ProductSkeleton = function ProductSkeleton(_ref) {
  _objectDestructuringEmpty(_ref);

  var t = useIntl();
  return __jsx(Card, {
    color: "white",
    rounded: true,
    className: styles.root()
  }, __jsx("div", {
    className: styles.group()
  }, __jsx("div", {
    className: styles.imageWrapper()
  }, __jsx("div", {
    className: styles.imagePlaceholderContainer()
  }, __jsx(Skeleton, {
    width: "full",
    height: "full"
  }))), __jsx("div", {
    className: styles.mainInfoContainer()
  }, __jsx("h4", {
    className: styles.title({
      placeholder: true
    })
  }, __jsx(Skeleton, {
    width: "90%",
    height: 22
  })), __jsx(_TwComponent, {
    rating: 0,
    iconSize: 16
  }), __jsx("div", {
    className: styles.details()
  }, __jsx(_TwComponent2, {
    width: 72,
    height: 18
  }), __jsx("span", {
    className: styles.detail({
      disabled: true
    })
  }, __jsx(Skeleton, {
    width: 72,
    height: 18
  })))), __jsx("div", {
    className: styles.description({
      placeholder: true
    })
  }, __jsx(Skeleton, {
    width: "100%",
    height: 13
  }), __jsx(Skeleton, {
    width: "85%",
    height: 13
  }), __jsx(Skeleton, {
    width: "75%",
    height: 13
  }))), __jsx(Button, {
    variant: "oval",
    color: "flavor",
    disabled: true,
    loading: false,
    className: styles.button()
  }, t.formatMessage({
    defaultMessage: "Add to cart",
    id: "cart.addToCart"
  })));
};

ProductSkeleton.displayName = "ProductSkeleton";
export default ProductSkeleton;