import _objectDestructuringEmpty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectDestructuringEmpty.js";
var __jsx = React.createElement;
import React from "react";
import { useIntl } from "react-intl";
import { Button } from "@/components/ui";
import useInterface from "@/hooks/useInterface.hook";
import styles from "./Filters.styles";

var Filters = function Filters(_ref) {
  _objectDestructuringEmpty(_ref);

  var t = useIntl();

  var _useInterface = useInterface(),
      openDialog = _useInterface.openDialog;

  var counter = 1;
  var active = counter > 0;
  return __jsx("div", {
    className: styles.root()
  }, __jsx(Button, {
    variant: "slim",
    className: styles.button(),
    onClick: function onClick() {
      return openDialog("FILTERS");
    }
  }, t.formatMessage({
    defaultMessage: "Filtrowanie:",
    id: "filters.component.label"
  }), __jsx("span", {
    className: styles.counter({
      active: active
    })
  }, counter)));
};

Filters.displayName = "Filters";
export default Filters;