import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread({}, {
    "display": "flex",
    "height": "100%",
    "flexDirection": "column",
    "justifyContent": "space-between",
    "padding": "0px !important"
  })),
  group: _css({}),
  imageWrapper: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "overflow": "hidden",
    "borderRadius": "1rem",
    "&:focus": {
      "outline": "2px solid transparent",
      "outlineOffset": "2px"
    }
  }), {}, {
    clipPath: "content-box"
  })),
  imageContainer: _css(_objectSpread(_objectSpread({}, {
    "--tw-aspect-h": "3",
    "position": "relative",
    "paddingBottom": "calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%)",
    "--tw-aspect-w": "4",
    "> *": {
      "position": "absolute",
      "height": "100%",
      "width": "100%",
      "top": "0",
      "right": "0",
      "bottom": "0",
      "left": "0"
    }
  }), {}, {
    backgroundColor: "$gray2"
  })),
  image: _css(_objectSpread({}, {
    "transitionProperty": "transform",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "transitionDuration": "300ms",
    "animationDuration": "300ms",
    "animationTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "&:hover": {
      "--tw-scale-x": "1.1",
      "--tw-scale-y": "1.1",
      "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
    }
  })),
  imagePlaceholderContainer: _css(_objectSpread({}, {
    "--tw-aspect-h": "3",
    "position": "relative",
    "paddingBottom": "calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%)",
    "--tw-aspect-w": "4",
    "> *": {
      "position": "absolute",
      "height": "100%",
      "width": "100%",
      "top": "0",
      "right": "0",
      "bottom": "0",
      "left": "0"
    },
    "overflow": "hidden",
    "borderRadius": "1rem"
  })),
  mainInfoContainer: _css(_objectSpread({}, {
    "paddingBottom": "0.5rem",
    "paddingTop": "1rem"
  })),
  title: _css(_objectSpread(_objectSpread({}, {
    "marginBottom": "0.25rem",
    "cursor": "pointer",
    "transitionDuration": "300ms",
    "animationDuration": "300ms",
    "transitionTimingFunction": "linear",
    "animationTimingFunction": "linear"
  }), {}, {
    fontFamily: "$tenor",
    fontSize: "$5",
    "&:hover": {
      color: "$default"
    },
    variants: {
      placeholder: {
        "true": _objectSpread({}, {
          "paddingTop": "0.25rem",
          "paddingBottom": "0.25rem"
        })
      }
    }
  })),
  details: _css(_objectSpread({}, {
    "marginTop": "0.75rem",
    "display": "flex",
    "flexWrap": "wrap",
    "alignItems": "center"
  })),
  price: _css(_objectSpread({}, {
    "marginRight": "0.75rem"
  })),
  detail: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "paddingLeft": "0.75rem"
  }), {}, {
    fontFamily: "$inter",
    fontSize: "$4",
    lineHeight: "$1",
    "&:before": _objectSpread(_objectSpread({}, {
      "position": "absolute",
      "left": "0px",
      "top": "50%",
      "height": "0.125rem",
      "width": "0.125rem",
      "borderRadius": "0.25rem"
    }), {}, {
      content: "",
      backgroundColor: "$gray9",
      transform: "translateY(-50%)"
    }),
    variants: {
      disabled: {
        "true": {
          "&:before": {
            backgroundColor: "$gray4"
          }
        }
      }
    }
  })),
  description: _css({
    color: "$gray6",
    "article > p": {
      lineHeight: "$9"
    },
    variants: {
      placeholder: {
        "true": _objectSpread({}, {
          "marginTop": "1.25rem",
          "marginBottom": "1.25rem",
          "display": "flex",
          "flexDirection": "column",
          "gap": "0.75rem"
        })
      }
    }
  }),
  button: _css(_objectSpread({}, {
    "marginTop": "1.25rem"
  })),
  discountTagContainer: _css({
    width: "100%"
  }),
  discountTag: _css({
    width: "fit-content"
  })
};
export default styles;