import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread({}, {
    "position": "relative"
  })),
  button: _css(_objectSpread(_objectSpread({}, {
    "display": "inline-flex",
    "flexDirection": "row",
    "alignItems": "center",
    "borderWidth": "1px !important",
    "borderColor": "transparent !important",
    "fontWeight": "400",
    "--tw-text-opacity": "1 !important",
    "color": "hsl(359 4% 45% / var(--tw-text-opacity)) !important",
    "&:hover": {
      "--tw-border-opacity": "1 !important",
      "borderColor": "hsl(359 4% 95% / var(--tw-border-opacity)) !important",
      "--tw-bg-opacity": "1 !important",
      "backgroundColor": "hsl(359 4% 98% / var(--tw-bg-opacity)) !important"
    }
  }), {}, {
    "& span": _objectSpread({}, {
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "@media (min-width: 640px)": {
        "fontSize": "1rem",
        "lineHeight": "1.5rem"
      }
    })
  })),
  counter: _css(_objectSpread(_objectSpread({}, {
    "marginLeft": "0.5rem",
    "display": "flex",
    "height": "1.5rem",
    "width": "1.5rem",
    "alignItems": "center",
    "justifyContent": "center",
    "borderRadius": "0.125rem",
    "borderWidth": "1px",
    "borderColor": "hsl(359 4% 85% / 0.5)",
    "--tw-bg-opacity": "1",
    "backgroundColor": "hsl(359 4% 95% / var(--tw-bg-opacity))",
    "fontSize": "0.75rem !important",
    "lineHeight": "1rem !important",
    "fontWeight": "600",
    "--tw-text-opacity": "1",
    "color": "hsl(359 4% 35% / var(--tw-text-opacity))"
  }), {}, {
    variants: {
      active: {
        "true": _objectSpread(_objectSpread({}, {
          "--tw-border-opacity": "1",
          "borderColor": "hsl(344 51% 87% / var(--tw-border-opacity))"
        }), {}, {
          backgroundColor: "$lighten",
          color: "$dark"
        })
      }
    }
  }))
};
export default styles;