import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread({}, {
    "position": "relative",
    "display": "flex",
    "flexDirection": "column",
    "alignItems": "flex-end",
    "justifyContent": "center"
  })),
  menu: _css(_objectSpread({}, {
    "position": "relative",
    "display": "flex",
    "flexDirection": "row",
    "alignItems": "center",
    "gap": "0px"
  })),
  label: _css(_objectSpread({}, {
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem",
    "fontWeight": "400",
    "--tw-text-opacity": "1",
    "color": "hsl(359 4% 45% / var(--tw-text-opacity))",
    "@media (min-width: 640px)": {
      "fontSize": "1rem",
      "lineHeight": "1.5rem"
    }
  })),
  skeleton: _css(_objectSpread({}, {
    "marginRight": "1rem",
    "@media (min-width: 1024px)": {
      "marginRight": "0.5rem"
    }
  })),
  loader: _css(_objectSpread({}, {
    "position": "absolute",
    "left": "-1.5rem",
    "marginLeft": "1rem",
    "marginRight": "1rem",
    "display": "block",
    "--tw-text-opacity": "1",
    "color": "hsl(344 51% 51% / var(--tw-text-opacity))"
  }))
};
export default styles;