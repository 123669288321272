var __jsx = React.createElement;
import Progress from "nextjs-progressbar";
import React, { Fragment } from "react";
import Cart from "@/cart/views/Cart.view";
import { Chat } from "@/components/common";
import { Footer, Main, Navbar, PromoBar, Root } from "@/components/ui";
import CookieConsent from "@/cookieConsent/views/CookieConsent.view";
import useInterface from "@/hooks/useInterface.hook";
import { Navbar as NavbarContext } from "@/navbar/navbar.context";
import Search from "@/search/Search.view";
import colors from "@/theme/theme.colors.tw";

var Layout = function Layout(_ref) {
  var children = _ref.children,
      _ref$withChat = _ref.withChat,
      withChat = _ref$withChat === void 0 ? true : _ref$withChat,
      _ref$withBorder = _ref.withBorder,
      withBorder = _ref$withBorder === void 0 ? true : _ref$withBorder;

  var _useInterface = useInterface(),
      navScrolled = _useInterface.navbar.navScrolled;

  return __jsx(Fragment, null, __jsx(Progress, {
    color: navScrolled ? colors.flavor["default"].dark : colors.common.white // color={navScrolled ? colors.flavor.default.dark : colors.flavor.default.default}
    ,
    startPosition: 0.3,
    stopDelayMs: 200,
    height: 3,
    showOnShallow: true,
    options: {
      showSpinner: false
    }
  }), __jsx(NavbarContext, null, __jsx(Root, null, __jsx(Cart, null), __jsx(PromoBar, {
    id: "__bar"
  }), __jsx(Navbar.Default, {
    withBorder: withBorder
  }), __jsx(Main, null, children), __jsx(Footer.Default, null), __jsx(CookieConsent, null), __jsx(Search, null))), withChat ? __jsx(Chat, null) : null);
};

Layout.displayName = "Layout";
export default Layout;