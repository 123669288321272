var __jsx = React.createElement;
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { useIntl } from "react-intl";
import { Image, Text } from "@/components/common";
import { Button, Card, ImagePlacecholder, Price, Tag } from "@/components/ui";
import { generateTags } from "@/components/ui/Tag/Tag.helpers";
import { up } from "@/helpers/screens.helpers";
import { scrollIntoView } from "@/helpers/scroll.helper";
import useCheckout from "@/hooks/useCheckout.hook";
import { useMediaQuery } from "@/hooks/useMediaQuery.hook";
import { AverageRating } from "@/product/components";
import { ScreenEnum } from "@/theme/theme.enums";
import { parseJsonString } from "~/src/components/common/Text/Text.helpers";
import { getVariantsSetDiscountPrice } from "~/src/helpers/product.helpers";
import useRegion from "~/src/hooks/useRegion.hook";
import { useProductListVariant } from "../../hooks/useProductListVariant.hook";
import styles from "./Product.styles";

var Product = function Product(_ref) {
  var product = _ref.product,
      variant = _ref.variant,
      clientReviewPages = _ref.clientReviewPages,
      _ref$action = _ref.action,
      action = _ref$action === void 0 ? "link" : _ref$action,
      testIdPrefix = _ref.testIdPrefix;
  var t = useIntl();
  var router = useRouter();

  var _useCheckout = useCheckout(),
      loading = _useCheckout.loading,
      submitting = _useCheckout.submitting;

  var _useRegion = useRegion(),
      currentChannel = _useRegion.currentChannel;

  var _useProductListVarian = useProductListVariant(variant),
      name = _useProductListVarian.name,
      isGiftCard = _useProductListVarian.isGiftCard,
      isAvailableForPurchase = _useProductListVarian.isAvailableForPurchase,
      media512Url = _useProductListVarian.media.media512Url,
      availableQuantity = _useProductListVarian.availableQuantity,
      price = _useProductListVarian.price,
      priceUndiscounted = _useProductListVarian.priceUndiscounted,
      volume = _useProductListVarian.volume,
      shortDescription = _useProductListVarian.shortDescription,
      clientReviews = _useProductListVarian.clientReviews,
      variantHref = _useProductListVarian.href,
      addToCart = _useProductListVarian.addToCart;

  var isUpMd = useMediaQuery(up(ScreenEnum.md), false);
  var variantsSetDiscountPrice = getVariantsSetDiscountPrice(product, currentChannel.currencyCode);
  var discountTag = variantsSetDiscountPrice.amount > 0 ? __jsx(React.Fragment, null, __jsx("span", null, t.formatMessage({
    id: "tag.variantsSetDiscount",
    defaultMessage: "In the set you save"
  })), __jsx(Price, {
    price: variantsSetDiscountPrice,
    priceOptions: {
      minimumFractionDigits: 2
    },
    bold: true,
    size: "xs"
  })) : null;
  var tags = generateTags(variant.product.attributes, {
    topLength: variantsSetDiscountPrice.amount > 0 ? 0 : 1,
    bottomLength: 1
  });
  var filteredClientReviewPages = clientReviewPages.filter(function (clientReviewPage) {
    return clientReviews.some(function (clientReviewAttribute) {
      return clientReviewAttribute.values.some(function (value) {
        return value.reference === clientReviewPage.id;
      });
    });
  });

  var handleClickRating = function handleClickRating() {
    router.push(variantHref).then(function () {
      scrollIntoView("__product-customers", {
        block: isUpMd ? "center" : "end"
      });
    });
  };

  return __jsx(Card, {
    color: "white",
    rounded: true,
    className: styles.root()
  }, __jsx("div", {
    className: styles.group()
  }, __jsx(Link, {
    href: variantHref,
    passHref: true
  }, __jsx("div", {
    className: styles.imageWrapper()
  }, __jsx(Tag, {
    variant: "slim"
  }, __jsx(Tag.Group, {
    variant: "slim"
  }, discountTag ? __jsx("div", {
    className: styles.discountTagContainer()
  }, __jsx(Tag.Item, {
    color: "white",
    className: styles.discountTag()
  }, discountTag)) : null, tags.top.map(function (_ref2) {
    var id = _ref2.id,
        label = _ref2.label,
        icon = _ref2.icon;
    return __jsx(Tag.Item, {
      color: "lighten",
      icon: icon,
      key: id
    }, label);
  })), __jsx(Tag.Group, {
    variant: "slim"
  }, tags.bottom.map(function (_ref3) {
    var id = _ref3.id,
        label = _ref3.label,
        icon = _ref3.icon;
    return __jsx(Tag.Item, {
      color: "light",
      icon: icon,
      key: id
    }, label);
  }))), media512Url ? __jsx("div", {
    className: styles.imageContainer()
  }, __jsx(Image, {
    src: media512Url,
    alt: "single product image",
    placeholder: "blur",
    fill: true,
    unoptimized: true,
    sizes: "(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 33vw",
    style: {
      objectFit: "cover",
      objectPosition: "center"
    }
  })) : __jsx("div", {
    className: styles.imagePlaceholderContainer()
  }, __jsx(ImagePlacecholder, {
    size: "lg"
  })))), __jsx("div", {
    className: styles.mainInfoContainer()
  }, __jsx(Link, {
    href: variantHref,
    legacyBehavior: true
  }, name && __jsx("h4", {
    className: styles.title(),
    "data-testid": "".concat(testIdPrefix !== null && testIdPrefix !== void 0 ? testIdPrefix : "", "product-").concat(product.slug, "-name")
  }, name)), __jsx(AverageRating, {
    pages: filteredClientReviewPages,
    iconSize: 16,
    clickAction: handleClickRating
  }), __jsx("div", {
    className: styles.details()
  }, price ? __jsx(Price, {
    price: price,
    priceUndiscounted: priceUndiscounted,
    fontFamily: "inter",
    size: "md",
    className: styles.price(),
    testIdPrefix: "".concat(testIdPrefix !== null && testIdPrefix !== void 0 ? testIdPrefix : "", "product-").concat(variant.product.slug, "-"),
    classPrefix: "".concat(testIdPrefix !== null && testIdPrefix !== void 0 ? testIdPrefix : "", "product-").concat(variant.product.slug, "-")
  }) : null, volume && !isGiftCard ? __jsx("span", {
    className: styles.detail()
  }, volume) : null)), parseJsonString(shortDescription) && __jsx("div", {
    className: styles.description()
  }, __jsx(Text, {
    text: shortDescription
  }))), action === "link" ? __jsx(Link, {
    href: variantHref,
    passHref: true,
    legacyBehavior: true
  }, __jsx(Button, {
    color: "flavorLight",
    width: "100%",
    variant: "oval"
  }, t.formatMessage({
    defaultMessage: "Find out more",
    id: "storefront.sections.products.product.button"
  }))) : action === "add-to-cart" ? __jsx(Button, {
    variant: "oval",
    color: "flavor",
    disabled: !isAvailableForPurchase || !(availableQuantity > 0) || submitting || loading,
    onClick: function onClick() {
      return addToCart(variant, 1, "list");
    },
    loading: false,
    className: styles.button(),
    "data-testid": "".concat(testIdPrefix !== null && testIdPrefix !== void 0 ? testIdPrefix : "", "product-").concat(variant.product.slug, "-add-to-cart-button")
  }, t.formatMessage({
    defaultMessage: "Add to cart",
    id: "cart.addToCart"
  })) : null);
};

Product.displayName = "Product";
export default Product;